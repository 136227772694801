import React, { useState } from 'react';
import './App.css';
import TranslatorForm from './TranslatorForm';
import TranslationResult from './TranslationResult';

function isTouchDevice() {
  return (('ontouchstart' in window) ||
     (navigator.maxTouchPoints > 0) ||
     (navigator.msMaxTouchPoints > 0));
}

function App() {
  const [translatedText, setTranslatedText] = useState('');
  const [loading, setLoading] = useState(false);
  const [translateButtonDisabled, setTranslateButtonDisabled] = useState(true);
  const [isCorporateOrigin, setIsCorporateOrigin] = useState(true);
  const [inputText, setInputText] = useState('');

  !isTouchDevice() && setTimeout(()=>{document.getElementById('left-text-area').focus()}, 0);

  const handleToggleOrigin = () => {
    setIsCorporateOrigin(prevState => !prevState);
    setTranslatedText(''); // Clear translated text
    setInputText(''); // Clear input text
    setTranslateButtonDisabled(true);
  };

  const handleInputTextChange = (s) => {
    setInputText(s);
    setTranslateButtonDisabled(s.trim() === '');
  }

  const handleSubmit = async (text) => {
    setLoading(true);

    try {
      // Determine translation mode based on origin
      const mode = isCorporateOrigin ? 'to_explicit' : 'to_corporate';

      const response = await fetch('https://04qh8pfq9g.execute-api.us-east-2.amazonaws.com/default/translate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ text, mode })
      });

      if (!response.ok) {
        throw new Error('Failed to fetch translation');
      }

      const data = await response.json();
      setTranslatedText(data);
    } catch (error) {
      console.error('Error:', error);
      setTranslatedText('An error occurred while fetching translation.');
    } finally {
      setTimeout(()=>{document.getElementById('left-text-area').focus()}, 0);
      setLoading(false);
    }
  };

  return (
    <div className="App">
      <img src='/logo.png' height='200px'></img>
      <h2>Unveil Corporate Bullsh*t with Brutal Honesty</h2>
      <div className="form-container">
        <div className="textarea-container">
          <div className='left-textarea-top'>
            <h3>{isCorporateOrigin ? 'English (corporate)' : 'English (explicit)'}</h3>
            <button onClick={handleToggleOrigin} className="change-origin-button">
              <img src="/arrows.png" alt="Change Origin" className="icon" />
            </button>
          </div>
          <TranslatorForm onSubmit={handleSubmit} disabled={loading} buttonDisabled={translateButtonDisabled} value={inputText} setValue={handleInputTextChange} />
        </div>
        <div className="textarea-container">
          <h3>{isCorporateOrigin ? 'English (explicit)' : 'English (corporate)'}</h3>
          <TranslationResult translatedText={translatedText} loading={loading} />
        </div>
      </div>
    </div>
  );
}

export default App;
