// TranslationResult.js

import React from 'react';

function TranslationResult({ inputText, translatedText, loading }) {
  return (
    <textarea
      value={loading ? 'Translating...' : translatedText}
      readOnly
      placeholder={'Translated text will appear here'}
      className="output-textarea"
    />
  );
}

export default TranslationResult;
