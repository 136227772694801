import React from 'react';

function TranslatorForm({ onSubmit, disabled, buttonDisabled, value, setValue }) {
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); // Prevent inserting newline
      !buttonDisabled && onSubmit(value); // Submit translation
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <textarea
        id='left-text-area'
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        disabled={disabled}
        placeholder="Enter text here"
      />
      <button type="submit" className="translate-button" disabled={buttonDisabled || disabled}>Translate</button>
    </form>
  );
}

export default TranslatorForm;
